import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// custom components
import Button from "components/CustomButtons/Button.jsx";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Loading from "sections/Loading.jsx";
// external modules
import { graphql, Link, navigate } from 'gatsby'
import Img from "gatsby-image";
// Auth
import { isAuthenticated, setCallbackLink } from 'Auth/AuthService';
// styles
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";

import image from "assets/img/backgrounds/bg7.jpg";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: false
    };
  }
  componentDidMount() {
    if (!isAuthenticated()) {
      const { pathname } = this.props.location;
      setCallbackLink(pathname);
      navigate("/login")
    }
  }
  render() {
    const { classes, data } = this.props;
    const officeCourses = data.office.edges;
    const netsmartCourses = data.netsmart.edges;

    return (
      <div>
        {isAuthenticated() ?
          <div>
            <Header
              brand="Able University"
              color="transparent"
              headerLink="/course-list"
              links={<HeaderLinks dropdownHoverColor="primary" />}
              fixed
              changeColorOnScroll={{
                height: 300,
                color: "primary"
              }}
            />
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${image}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    md={8}
                    sm={8}
                    className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
                  >
                    <h1 className={classes.title}>Course List</h1>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <div className={classes.spacer} />
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem md={8}>
                  <h5>Scroll down to see a list of all of the courses we currently offer.</h5>
                </GridItem>
              </GridContainer>
              <div className={classes.spacer} />
              <GridContainer>
                <GridItem>
                  <h3 style={{ fontWeight: "bold" }}>Netsmart & DeVero</h3>
                </GridItem>
                {netsmartCourses.map(course => {
                  const { slug, cover, title, description } = course.node;
                  return (
                    <GridItem sm={4} key={title}>
                      <Card style={{ width: "20rem" }}>
                        <Img
                          style={{ height: "180px", width: "100%", display: "block" }}
                          className={classes.imgCardTop}
                          fixed={cover.fixed}
                          alt="Card-img-cap"
                        />
                        <CardBody>
                          <h4 style={{ fontWeight: "bold", color: "#000"}}>{title}</h4>
                          <div dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.excerpt}} />
                        </CardBody>
                        <CardFooter>
                          <Link to={`/${slug}`} style={{ color: "#fff"}}>
                            <Button
                              color="primary"
                            >
                              View course
                            </Button>
                          </Link>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  )
                })}
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <h3 style={{ fontWeight: "bold" }}>Microsoft Office</h3>
                </GridItem>
                {officeCourses.map(course => {
                  const { slug, cover, title, description } = course.node;
                  return (
                    <GridItem sm={4} key={title}>
                      <Card style={{ width: "20rem" }}>
                        <Img
                          style={{ height: "180px", width: "100%", display: "block" }}
                          className={classes.imgCardTop}
                          fixed={cover.fixed}
                          alt="Card-img-cap"
                        />
                        <CardBody>
                          <h4 style={{ fontWeight: "bold", color: "#000"}}>{title}</h4>
                          <div dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.excerpt}} />
                        </CardBody>
                        <CardFooter>
                          <Link to={`/${slug}`} style={{ color: "#fff"}}>
                            <Button
                              color="primary"
                            >
                              View course
                            </Button>
                          </Link>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  )
                })}
              </GridContainer>
              <Footer
                content={
                  <div>
                    <div className={classes.left}>
                      <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://portal.ablehomecarellc.com/dashboard/"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Portal
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://outlook.office365.com"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Webmail
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://policies.ablehomecarellc.com"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Policies
                          </a>
                        </ListItem>
                      </List>
                    </div>
                    <div className={classes.right}>
                      &copy; {1900 + new Date().getYear()} Able Home Care LLC
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        :
        <Loading />
        }
      </div>
    )
  }
};

export const query = graphql`
  query CourseList {
    netsmart: allContentfulCourses(filter: { courseType: { eq: "Netsmart" } }) {
      edges {
        node {
          cover {
            fixed(height: 180, width: 320) {
              ...GatsbyContentfulFixed
            }
          }
          title,
          slug,
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 125)
            }
          }
        }
      }
    }
    office: allContentfulCourses(filter: { courseType: { eq: "Microsoft Office" } }) {
      edges {
        node {
          cover {
            fixed(height: 180, width: 320) {
              ...GatsbyContentfulFixed
            }
          }
          title,
          slug,
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 150)
            }
          }
        }
      }
    }
  }
`

export default withStyles(headersStyle)(HomePage);
